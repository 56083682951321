<template>
	<div class="roadmap">
		<div class="container mt-5">
			<h1 class="mt-5">AlphaBatem Lab's Roadmap</h1>
			<i class="small white">Last Progress Update: 09/12/22 </i>

			<div class="year my-5" v-for="year in years" :key="year.name">
				<h2>{{ year.name }}</h2>
				<hr class="divider">
				<div class="row mt-5">
					<div class="col-3">
						<div class="card">
							<h3>Q1</h3>
							<ul class="mt-4">
								<li :class="`my-4 ${feat.completed ? 'comp' : ''}`" v-for="(feat,key) in year.q1" :key="key">{{ feat.name }}</li>
							</ul>
						</div>
					</div>
					<div class="col-3">
						<div class="card">
							<h3>Q2</h3>
							<ul class="mt-4">
								<li :class="`my-4 ${feat.completed ? 'comp' : ''}`" v-for="(feat,key) in year.q2" :key="key">{{ feat.name }}</li>
							</ul>
						</div>
					</div>
					<div class="col-3">
						<div class="card">
							<h3>Q3</h3>
							<ul class="mt-4">
								<li :class="`my-4 ${feat.completed ? 'comp' : ''}`" v-for="(feat,key) in year.q3" :key="key">{{ feat.name }}</li>
							</ul>
						</div>
					</div>
					<div class="col-3">
						<div class="card">
							<h3>Q4</h3>
							<ul class="mt-4">
								<li :class="`my-4 ${feat.completed ? 'comp' : ''}`" v-for="(feat,key) in year.q4" :key="key">{{ feat.name }}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";

export default {
	name: "Roadmap",
	components: {Footer},
	data() {
		return {
			years: [
				{
					name: "2022",
					q1: [
						{name: "Marketing", completed: true},
						{name: "Multiplayer Engine", completed: true},
						{name: "Oasis Engine ALPHA", completed: true},
					],
					q2: [
						{name: "NFT Import", completed: true},
						{name: "Land Sale", completed: true},
						{name: "Metaverse Explorer", completed: true},
						{name: "On Chain Analytics", completed: true},
						{name: "In-Metaverse Crypto Payments", completed: true},
					],
					q3: [
						{name: "Metaverse Fabricator", completed: true},
						{name: "Cities & Buildings", completed: true},
						{name: "Mystery Box VRF", completed: true},
						{name: "Dialogue System", completed: true},
						{name: "Blok Host", completed: true},
					],
					q4: [
						{name: "No-Code Actions", completed: true},
						{name: "Pets & Followers", completed: true},
						{name: "Scriptable Items", completed: true},
						{name: "Marketplace Integrations", completed: true},
					],
				},
				{
					name: "2023",
					q1: [
						{name: "Edit3D BETA", completed: false},
						{name: "Advertising Platform", completed: true},
						{name: "Guilds", completed: false},
						{name: "AR Wearables", completed: false},
					],
					q2: [
						{name: "Oasis Engine BETA", completed: false},
						{name: "Coin Offering", completed: false},
						{name: "Time Token Exchange", completed: false},
						{name: "NPC AI SDK", completed: false},
					],
					q3: [
						{name: "IOS App Launch", completed: false},
						{name: "Contract Auditing", completed: false},
						{name: "VR Integration", completed: false},
						{name: "Geospatial SDK", completed: false},
					],
					q4: [
						{name: "AR World Events", completed: false},
						{name: "Oasis Engine Extensions", completed: false},
						{name: "AR Hardware Device", completed: false},
					],
				}
			]
		}
	}
}
</script>

<style scoped>

ul {
	font-weight: normal;
}

.comp {
	color: var(--link-primary);
}

.card {
	min-height: 400px;
}
</style>